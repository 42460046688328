import React from 'react';
import { 
  Box, 
  Link, 
  Stack, 
  Typography 
} from '@mui/joy';
import { useSubscription } from '../context/SubscriptionContext';
import './TrialBanner.css';

const TrialBanner = () => {
  const { isLoading, subscription, daysLeft } = useSubscription();

  // If loading or no subscription data, don't show banner
  if (isLoading || !subscription || subscription?.tier_id !== 1) return null;

  const message = daysLeft === 0 
      ? "Your trial has ended. Please select a plan to continue using All Day TA:" 
      : `${daysLeft} ${daysLeft === 1 ? 'day' : 'days'} left in your free trial. Enjoying All Day TA?`;

  return (
    <Box className="trial-banner">
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ flexWrap: 'wrap' }} gap={1}>
        <Typography level="body-md" sx={{ color: '#fff' }}>
          {message}
        </Typography>
        <Typography level="body-md" sx={{ color: '#fff' }}>
          <Link 
            href="/subscriptions?upgrade-trial=true"
            target="_blank" 
            rel="noopener" 
            sx={{ textDecoration: 'underline', color: '#fff' }}
          >
            Select a Plan Now
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
};

export default TrialBanner;