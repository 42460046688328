import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoadingIcon from '../assets/LoadingLogo.gif';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, loading } = useContext(AuthContext);
    const location = useLocation();

    if (loading) {
    // You might want to show a loading spinner here
    return <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
            <img
              src={LoadingIcon}
              alt="Screen loading"
              style={{
                  width: '3rem',
                  height: '3rem',
                  display: 'block',
                  marginLeft: '1.5rem',
              }}
              />
              Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login/admin" state={{ from: location }} replace />;
    }

    return children;
};

export default ProtectedRoute;
