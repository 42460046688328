import axios from 'axios';
import * as React from "react";
import { useState, useEffect } from "react";
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Sheet,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import UploadIcon from '@mui/icons-material/UploadFile';
import LoadingIcon from '../assets/LoadingLogo.gif';
import DeleteFileModal from '../components/DeleteFileModal';
import EditFileModal from '../components/EditFileModal';
import ShareFilesModal from './ShareFilesModal';
import NewModuleModal from '../components/NewModuleModal';
import { v4 as uuidv4 } from 'uuid';

import './Tables.css';

function labelDisplayedRows({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function hasError(status) {
  return status.includes("Error");
}

function getComparator(order, orderBy) {
  return (a, b) => {
    // First, check for errors and prioritize them
    if (hasError(a.status) && !hasError(b.status)) return -1;
    if (!hasError(a.status) && hasError(b.status)) return 1;

    // Always put "Loading" status at the top
    if ((a.status === "Loading" || a.status === "Processing") && 
        (b.status !== "Loading" && b.status !== "Processing")) return -1;
    if ((b.status === "Loading" || b.status === "Processing") && 
        (a.status !== "Loading" && a.status !== "Processing")) return 1;

    // For other cases, use the existing comparison logic
    const comparison = descendingComparator(a, b, orderBy);
    return order === 'desc' ? comparison : -comparison;
  };
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'File Name',
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: false,
    label: 'Active',
    sortable: false,
    info: 'Active files are used by All Day TA to answer student questions.',
  },
  {
    id: 'optional',
    numeric: false,
    disablePadding: false,
    label: 'Optional',
    sortable: false,
    info: 'Files marked as optional are downweighted by All Day TA when answering student questions.',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

function InfoTooltip({ info, open, onClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <DialogContent>
          <Typography level="body-lg" mt={2}>
            {info}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} sx={{maxWidth:'max-content'}}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}

function EnhancedTableToolbar(props) {
  const { totalFiles, rowsPerPage, page, statusCounts  } = props;
  const pageRowCount = Math.min(rowsPerPage, totalFiles - page * rowsPerPage);
  const activeFiles = totalFiles - (statusCounts.loading + statusCounts.processing + statusCounts.updating + statusCounts.replacing);
  const hasNonActiveFiles = activeFiles < totalFiles;

  const getStatusString = () => {
    const statuses = [];
    if (statusCounts.loading > 0) statuses.push(`${statusCounts.loading} Loading`);
    if (statusCounts.processing > 0) statuses.push(`${statusCounts.processing} Processing`);
    if (statusCounts.updating > 0) statuses.push(`${statusCounts.updating} Updating`);
    if (statusCounts.replacing > 0) statuses.push(`${statusCounts.replacing} Replacing`);
    
    return statuses.join(', ');
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        py: 1,
        mb: 1,
      }}
    >
      <Typography sx={{ flex: '1 1 100%' }} level="title-lg">
        Viewing {pageRowCount} of {totalFiles} {totalFiles === 1 ? 'file' : 'files'}
        {hasNonActiveFiles && (
          <>
            {' ('}
            {getStatusString()}
            {')'}
          </>
        )}
      </Typography>
    </Box>
  );
}

const removeFileExtension = (title) => {
  // Split the title into parts based on dots
  const parts = title.split('.');
  
  // If there's only one part (no dots) or the last part isn't a common file extension, return the original title
  if (parts.length === 1 || !isCommonFileExtension(parts[parts.length - 1])) {
    return title;
  }
  
  // Remove the last part (the extension) and join the rest
  return parts.slice(0, -1).join('.');
};

// Helper function to check if a string is a common file extension
const isCommonFileExtension = (ext) => {
  const commonExtensions = ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv', 'jpg', 'jpeg', 'png', 'gif', 'mp3', 'mp4', 'zip', 'rar'];
  return commonExtensions.includes(ext.toLowerCase());
};

export default function FileTable( { files, setFiles, refresh } ) {
  //Tracking file status
  const isFileProcessing = (row) => {
    return row.status === "Loading" || row.status === "Processing" || row.status === "Enhancing" ||
           row.status === "Replacing..." || row.status === "Updating Title..." || row.status === "Currently Deleting...";
  };

  const isRowSelectable = (row) => {
    return row.status === "Complete" || row.status === "success" || hasError(row.status);
  };

  function hasError(status) {
    return status.includes("Error");
  }

  //States
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [selectedForSharing, setSelectedForSharing] = useState([]);
  const [shareCode, setShareCode] = useState('')
  const [selectedRow, setSelectedRow] = useState(null);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [rows, setRows] = useState(files);
  const [replacingFileId, setReplacingFileId] = useState(null); // Track file being replaced
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('')
  const [totalFiles, setTotalFiles] = useState(0); 
  const [statusCounts, setStatusCounts] = useState({
    loading: 0,
    processing: 0,
    updating: 0,
    replacing: 0
  });

  useEffect(() => {
    const counts = files.reduce((acc, file) => {
      if (file.status === "Loading") acc.loading++;
      else if (file.status === "Processing") acc.processing++;
      else if (file.status === "Updating...") acc.updating++;
      else if (file.status === "Replacing...") acc.replacing++;
      return acc;
    }, { loading: 0, processing: 0, updating: 0, replacing: 0 });

    setStatusCounts(counts);
    setTotalFiles(files.length);
  }, [files]);

  useEffect(() => {
    setRows(files);
  }, [files]);

  const handleRequestSort = (event, property) => {
    if (property === 'active' || property === 'optional') return;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //Calculate selectable rows
  const selectableRowCount = stableSort(rows, getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .filter(row => isRowSelectable(row)).length;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .filter(row => isRowSelectable(row));
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleShareClick = async () => {
    if (selected.length === 0) {
      setErrorText("Please select files to share.");
      setShowError(true);
      setTimeout(() => setShowError(false), 8000); // Hide the error after 8 seconds
      return;
    }

    try {
      const share_code = uuidv4();
      const file_ids = selected.map((file) => file.file_id);
      const file_titles = selected.map(file => {return {title: file.title}});
      await axios.post('/api/share', { data: { file_ids: file_ids, share_key: share_code } });
      setSelectedForSharing(file_titles);
      setShareCode(share_code);
      setShareModalOpen(true);
    } catch (error) {
      setErrorText("Failed to share files. Please try again.");
      setShowError(true);
      setTimeout(() => setShowError(false), 8000); // Hide the error after 8 seconds
    }
  };

  const handleClick = (event, file) => {
    if (!isRowSelectable(file)) {
      return; // Do nothing if the file is processing or has an error
    }

    const selectedIndex = selected.findIndex((f) => f.id === file.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, file);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleActiveChange = async (fileId, newIsActive, orgId, courseId) => {
    try {
      // Update file status in FileTable immediately
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.file_id === fileId ? { ...file, isactive: newIsActive, status: "Updating..." } : file
        )
      );
    
      const response = await axios.patch(`/api/update_isactive/${orgId}/${courseId}/${fileId}/`, {
        isactive: newIsActive
      });

      if (response.status === 200) {
        //console.log('API Response - isactive:', response.data?.isactive);
        //console.log('API Response - message:', response.data?.message);
        //console.log(`Successfully updated the isactive status to ${newIsActive}`);
        // Update the file's isactive status in the local state
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.file_id === fileId ? { ...file, status: "Complete", isactive: newIsActive } : file
          )
        );
      } else {
        //console.error('Error updating isactive status:', response.data.status);
        // Revert the file's status and isactive in the local state
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.file_id === fileId ? { ...file, status: "Error", isactive: !newIsActive } : file
          )
        );
      }
    } catch (error) {
      //console.error('Error updating isactive status:', error.response ? error.response.data.message : error.message);
      // Revert the file's status and isactive in the local state
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.file_id === fileId ? { ...file, status: "Error", isactive: !newIsActive } : file
        )
      );
    }
  };

  const handleOptionalChange = async (fileId, newIsOptional, orgId, courseId) => {
    try {
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.file_id === fileId ? { ...file, isoptional: newIsOptional, status: "Updating..." } : file
        )
      );
    
      const response = await axios.patch(`/api/update_isoptional/${orgId}/${courseId}/${fileId}/`, {
        isoptional: newIsOptional
      });

      if (response.status === 200) {
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.file_id === fileId ? { ...file, status: "Complete", isoptional: newIsOptional } : file
          )
        );
      } else {
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.file_id === fileId ? { ...file, status: "Error", isoptional: !newIsOptional } : file
          )
        );
      }
    } catch (error) {
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.file_id === fileId ? { ...file, status: "Error", isoptional: !newIsOptional } : file
        )
      );
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const isSelected = (id) => selected.some((file) => file.id === id);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - files.length) : 0;

  // Functions to open modals
  const handleEditClick = (row) => {
    //console.log("Edit clicked for row ID:", row.id, row.title);
    setSelectedRow(row);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (row) => {
    //console.log("Delete clicked for row ID:", row.id, row.title);
    setFilesToDelete([row]);
    setDeleteModalOpen(true);
  };

  const handleDeleteFile = async (file) => {
    //console.log("handleDeleteFile clicked");
    setFiles((prevFiles) =>
      prevFiles.map((f) =>
        f.file_id === file.file_id ? { ...f, status: 'Currently Deleting...' } : f
      )
    );
  };

  const handleReplaceFile = (fileId, newFileName) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId ? { ...file, status: "Replacing...", filename: newFileName || file.filename } : file
      )
    );
    setReplacingFileId(fileId);
  };

  const handleUpdateTitle = (fileId, newFileName) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId ? { ...file, status: "Updating Title...", filename: newFileName || file.filename } : file
      )
    );
    setReplacingFileId(fileId);
  };


  const handleReplaceComplete = (fileId, newFileName) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId
          ? { ...file, filename: newFileName || file.filename, status: "Complete" }
          : file
      )
    );
    setReplacingFileId(null);
  };

  // for deleting multiple files after selection of files
  const handleDeleteSelectedFiles = async () => {
    if (selected.length === 0) {
      setErrorText("Please select files to delete.")
      setShowError(true);
      setTimeout(() => setShowError(false), 8000); // Hide the error after 8 seconds
      return;
    }

    setFilesToDelete(selected);
    setDeleteModalOpen(true);
  };

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, page, rowsPerPage, selectableRowCount } = props;
    const [openTooltip, setOpenTooltip] = useState(null);
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const pageRowCount = Math.min(rowsPerPage, rowCount - page * rowsPerPage);
    const isAllSelected = numSelected === selectableRowCount && selectableRowCount > 0;
    const isPartiallySelected = numSelected > 0 && numSelected < selectableRowCount;

    const handleInfoClick = (event, headCell) => {
      event.stopPropagation();
      setOpenTooltip(headCell.id);
    };

    return (
      <thead>
        <tr>
          <th>
            <Checkbox
              color="primary"
              variant="outlined"
              indeterminate={isPartiallySelected}
              checked={isAllSelected}
              onChange={onSelectAllClick}
              slotProps={{
                input: {
                  "aria-label": "Select all files",
                },
              }}
              sx={{ verticalAlign: "sub" }}
            />
          </th>
          {headCells.map((headCell) => {
            const active = orderBy === headCell.id;
            return (
              <th
                key={headCell.id}
                aria-sort={
                  active ? { asc: 'ascending', desc: 'descending' }[order] : undefined
                }
              > 
              <Stack direction="row" alignItems="center" spacing={1}>
                {headCell.sortable !== false ? (
                <Link
                  underline="none"
                  color="primary"
                  textColor={active ? 'primary.plainColor' : undefined}
                  component="button"
                  onClick={createSortHandler(headCell.id)}
                  fontWeight="lg"
                  startDecorator={
                    headCell.numeric ? (
                      <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                    ) : null
                  }
                  endDecorator={
                    !headCell.numeric ? (
                      <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                    ) : null
                  }
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                    '&:hover': { '& svg': { opacity: 1 } },
                  }}
                >
                  {headCell.label}
                  {active ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </Link>
                ) : (
                    headCell.label // If not sortable, just display the label
                )}
                {headCell.info && (
                  <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    aria-label={`Additional information about ${headCell.label} checkbox`}
                    onClick={(event) => handleInfoClick(event, headCell)}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                )}
              </Stack>
              {headCell.info && (
                <InfoTooltip
                  info={headCell.info}
                  open={openTooltip === headCell.id}
                  onClose={() => setOpenTooltip(null)}
                />
              )}
              </th>
            );
          })}
          <th>
            Actions
          </th>
        </tr>
      </thead>
    );
  }
  
  return (
    <Sheet variant="plain">
      <EnhancedTableToolbar
        totalFiles={totalFiles}
        rowsPerPage={rowsPerPage}
        page={page}
        statusCounts={statusCounts}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} className="table-toolbar">
        <Stack direction="row" alignItems="center" spacing={1}>
          {/* Uncomment when implementing modules in files list
          <NewModuleModal />
          <Button
            startDecorator={<LogoutOutlinedIcon />}
            color="neutral"
            variant="plain"
          >
            Move
          </Button>*/}
          <Button
            startDecorator={<UploadIcon />}
            color="neutral"
            variant="soft"
            onClick={handleShareClick}
          >
            Share Files
          </Button>
          <Button
            startDecorator={<DeleteIcon />}
            color="neutral"
            variant="soft"
            onClick={handleDeleteSelectedFiles}
          >
            Delete Files
          </Button>
          {showError && (
            <Alert
              color="danger"
              size="sm"
              variant="outlined"
              role="alert"
              aria-live="polite"
              endDecorator={
                <Button size="sm" variant="soft" color="danger" onClick={() => setShowError(false)}>
                  Dismiss
                </Button>
              }
            >
              {errorText}
            </Alert>
          )}
          {selected.length > 0 && (
            <Typography level="component-sm">
              {selected.length} {selected.length === 1 ? 'file' : 'files'} selected
            </Typography>
          )}
        </Stack>
        { /* Pagination Component */}
        <Stack direction="row" alignItems="center" gap={2}>
          <FormControl orientation="horizontal" size="md">
            <FormLabel>Rows/Page:</FormLabel>
            <Select onChange={handleChangeRowsPerPage} size="sm" value={rowsPerPage}>
              <Option value={10}>10</Option>
              <Option value={25}>25</Option>
              <Option value={50}>50</Option>
            </Select>
          </FormControl>
          <Typography level="body-sm" sx={{ fontFamily: 'UbuntuMedium'}}>
            {labelDisplayedRows({
              from: totalFiles === 0 ? 0 : page * rowsPerPage + 1,
              to: Math.min((page + 1) * rowsPerPage, totalFiles),
              count: totalFiles,
            })}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Previous Page"
              disabled={page === 0}
              onClick={() => handleChangePage(page - 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Next Page"
              disabled={
                totalFiles !== -1
                  ? page >= Math.ceil(totalFiles / rowsPerPage) - 1
                  : false
              }
              onClick={() => handleChangePage(page + 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Stack>
        { /* End Pagination Component */}
      </Stack>
        <Alert
          variant="soft"
          color="neutral"
          startDecorator={<TouchAppIcon />}
          sx={{
            display: 'none', // Hidden by default
            '@media (max-width: 820px)': {
              marginTop:'1rem',
              display: 'flex',
              justifyContent: 'center',
            }
          }}
          >
          Scroll horizontally to view full table
        </Alert>
      <Box sx={{ 
        width: '100%', 
        overflowX: 'auto', 
        '@media (max-width: 820px)': {
          width: '100vw',
          margin: '0 -1rem',
        }
      }}>
      <Table
        hoverRow
        sx={{
          boxSizing: 'border-box',
          tableLayout: 'auto',
          "& thead th:nth-of-type(1)": {
            width: "1.5rem",
          },
          "& thead th:nth-of-type(2)": {
            width: "50%",
          },
          "& tr > *:nth-of-type(n+6)": { textAlign: "right" },
          minWidth: '820px', // Ensure the table has a minimum width
          '@media (max-width: 820px)': {
            width: '100%',
          }
        }}
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={files.length}
          page={page}
          rowsPerPage={rowsPerPage}
          selectableRowCount={selectableRowCount}  
        />
        <tbody>
          {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
              const isItemSelected = selected.some((selectedItem) => selectedItem.id === row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              const hasErrorStatus = hasError(row.status);
              const isProcessing = isFileProcessing(row);

              return (
                <tr
                  tabIndex={-1}
                  key={row.id}
                  style={{
                    ...(isItemSelected || (isProcessing && row.status !== "Enhancing")
                      ? {
                          '--TableCell-dataBackground':
                            'var(--joy-palette-neutral-200)',
                          '--TableCell-headBackground':
                            'var(--joy-palette-neutral-200)',
                        }
                      : {}),
                  }}
                >
                  <td>
                    <Checkbox
                      color="primary"
                      variant="outlined"
                      checked={isItemSelected}
                      onChange={(event) => handleClick(event, row)}
                      aria-label={`Select ${row.title}`}
                      disabled={!isRowSelectable(row)}
                      slotProps={{
                        input: {
                          'aria-labelledby': labelId,
                        },
                      }}
                      sx={{ verticalAlign: 'top' }}
                    />
                  </td>
                  <th id={labelId} scope="row" sx={{ alignItems:'center' }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                    {isProcessing && row.status !== "Enhancing" && (
                      <CircularProgress
                        color="primary"
                        size="sm"
                        variant="soft"
                      />
                    )}
                    {hasErrorStatus && (
                      <ErrorOutlineIcon color="danger"/>
                    )}
                    <Typography>{removeFileExtension(row.title)}</Typography>
                    </Stack>
                  </th>
                  <td>
                      <Checkbox
                        color="primary"
                        variant="outlined"
                        checked={row.isactive && !hasErrorStatus}
                        onChange={(event) => handleActiveChange(row.file_id, event.target.checked, row.org_id, row.course_id)}
                        disabled={isProcessing || hasErrorStatus || row.status !== "Complete" && row.status !== "success"}
                        slotProps={{
                          input: {
                            'aria-labelledby': `Set ${row.title} as active`,
                            'aria-label':'File Active',
                          },
                        }}
                      />
                  </td>
                  <td>
                      <Checkbox
                        color="primary"
                        variant="outlined"
                        checked={row.isoptional && !hasErrorStatus}
                        onChange={(event) => handleOptionalChange(row.file_id, event.target.checked, row.org_id, row.course_id)}
                        disabled={isProcessing || hasErrorStatus || row.status !== "Complete" && row.status !== "success"}
                        slotProps={{
                          input: {
                            'aria-labelledby': `Set ${row.title} as optional`,
                            'aria-label':'File Optional',
                          },
                        }}
                      />
                  </td>
                  <td>
                    {row.status === "success" ? "Complete" : row.status}
                  </td>
                  <td>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1} >
                        <IconButton 
                          variant="plain" 
                          onClick={() => handleEditClick(row)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              handleEditClick(row);
                            }
                          }}
                          disabled={isProcessing}
                          aria-label={`Edit ${row.title}`}
                        >
                          <CreateIcon />
                        </IconButton>
                      <IconButton
                        variant="plain"
                        aria-label={`Delete ${row.title}`}
                        disabled={isProcessing}
                        onClick={() => handleDeleteClick(row)}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </td>
                </tr>
              );
            })}
          {emptyRows > 0 && (
            <tr
              style={{
                height: `calc(${emptyRows} * 40px)`,
                "--TableRow-hoverBackground": "transparent",
              }}
            >
              <td colSpan={4} aria-hidden />
            </tr>
          )}
        </tbody>
      </Table>
      </Box>
      <DeleteFileModal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setFilesToDelete([]);
        }}
        files={filesToDelete}
        onDelete={handleDeleteFile}
        onConfirmDelete={(file) => {
          setSelected([]);
          setFiles((prevFiles) => prevFiles.filter((f) => f.file_id !== file.file_id));
        }}
      />
      <EditFileModal 
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        file={selectedRow}
        setFiles={setFiles}
        handleReplaceFile={handleReplaceFile}
        refresh={refresh}
        handleUpdateTitle={handleUpdateTitle}
      />
      <ShareFilesModal
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        shareFiles={selectedForSharing}
        share_code={shareCode}
      />
    </Sheet>
  );
}
