// Updated slugify function based on the provided Python implementation
export const slugify = (text) => {
  // Convert to lowercase
  let slug = text.toLowerCase();
  // Replace spaces with hyphens
  slug = slug.replace(/\s/g, '-');
  // Remove any characters that are not alphanumeric, hyphens, underscores, or forward slashes
  slug = slug.replace(/[^\w\-_/]/g, '');
  // Remove leading and trailing hyphens
  slug = slug.replace(/^-+|-+$/g, '');
  // Replace multiple consecutive hyphens with a single hyphen
  slug = slug.replace(/-+/g, '-');
  // Remove leading and trailing forward slashes
  slug = slug.replace(/^\/+|\/+$/g, '');
  return slug;
};
