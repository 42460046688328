import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SubscriptionContext = createContext(null);

export const SubscriptionProvider = ({ children }) => {
    const location = useLocation();
    const [subscriptionState, setSubscriptionState] = useState({
        isLoading: true,
        error: null,
        subscription: null,
        daysLeft: null,
        isTrialExpired: false,
        courseId: null
    });

    // Check if we're on a public route
    const isPublicRoute = () => {
        const publicRoutes = [
            '/login/admin',
            '/forgot-password',
            '/reset-password',
            '/sign-up',
            '/unauthorized'
        ];
        return publicRoutes.includes(location.pathname);
    };

    const fetchSubscriptionData = async () => {
        // Skip fetch for public routes
        if (isPublicRoute()) {
            setSubscriptionState(prev => ({
                ...prev,
                isLoading: false
            }));
            return;
        }

        try {
            // Prepare subscription request params
            const params = {};
            if (isStudentChatbotRoute()) {
                params.course_url = location.pathname;
            } else {
                // Admin route handling
                const authResponse = await axios.get('/api/check-auth', { 
                    withCredentials: true 
                });
                const currentCourseId = authResponse.data.last_course_present;
                
                if (currentCourseId) {
                    params.course_id = currentCourseId;
                }
            }

            const subscriptionResponse = await axios.get('/api/subscription', {
                params,
                withCredentials: true
            });
            
            if (subscriptionResponse.data.success) {
                const hasSubscription = subscriptionResponse.data.tier_id && (params.course_id || params.course_url);
                const daysLeft = hasSubscription && subscriptionResponse.data.tier_id === 1 ? calculateTrialDaysLeft(subscriptionResponse.data.start_date) : null;
                
                setSubscriptionState({
                    isLoading: false,
                    error: null,
                    subscription: hasSubscription ? subscriptionResponse.data : null,
                    daysLeft,
                    isTrialExpired: hasSubscription && daysLeft === 0,
                    courseId: params.course_id || null
                });
            }
            
        } catch (error) {
            setSubscriptionState({
                isLoading: false,
                error: error,
                subscription: null,
                daysLeft: null,
                isTrialExpired: false,
                courseId: null
            });
        }
    };

    const calculateTrialDaysLeft = (startDate) => {
        const start = new Date(startDate);
        const today = new Date();
        const trialLength = 14;
        
        start.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        
        const diffTime = Math.abs(today - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return Math.max(0, trialLength - diffDays);
    };

    // Helper function to determine if we're on a student chatbot route
    const isStudentChatbotRoute = () => {
        const adminRoutes = [
            '/preview', '/create-org', '/signup/admin', '/course-info', 
            '/create-course', '/files', '/', '/forgot-password', 
            '/settings', '/sign-up', '/subscriptions', '/your-subscription', '/summaries', 
            '/login/admin', '/reset-password'
        ];
        return !adminRoutes.includes(location.pathname);
    };

    // Change subscription when course changes
    useEffect(() => {
        const handleCourseChanged = (event) => {
            fetchSubscriptionData();
        };

        window.addEventListener('courseChanged', handleCourseChanged);
        return () => {
            window.removeEventListener('courseChanged', handleCourseChanged);
        };
    }, []);

    // Initial fetch and route change handling
    useEffect(() => {
        if (!isPublicRoute()) {
            fetchSubscriptionData();
        }
    }, [location.pathname]);

    return (
        <SubscriptionContext.Provider value={{
            ...subscriptionState,
            refreshSubscription: fetchSubscriptionData  // Expose refresh function
        }}>
            {children}
        </SubscriptionContext.Provider>
    );
};

export const useSubscription = () => {
    const context = useContext(SubscriptionContext);
    if (context === null) {
        throw new Error('useSubscription must be used within a SubscriptionProvider');
    }
    return context;
};