import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormHelperText,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Snackbar,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import Container from '../components/Container';
import DragAndDropSyllabus from '../components/DragAndDropSyllabus';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getCurrentAdmin } from '../ProfessorsRetrieveAPI';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingIcon from '../assets/LoadingLogo.gif';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';
import { getAssociatedCoursesListForAdmin } from '../CourseRetrieveAPI';
import { getOrgName } from '../OrgRetrieveAPI';
import DatePicker, { validateAndAdjustDay, validateDates } from '../components/DatePicker';
import { slugify } from '../components/UrlHandler';

const FileTypeAlert = ({ onClose }) => {
    return (
        <Alert
          variant="outlined"
          color="primary"
          startDecorator={<InfoOutlinedIcon />}
          sx={{ mt:2, mb: 2 }}
          role="alert"
          aria-live="polite"
        >
          PDF files may take longer to process (roughly 10-15 seconds per page).
        </Alert>
  );
};

export default function CreateCourse({ headerRef }) {
  const navigate = useNavigate();
  const [adminInSession, setAdminInSession] = useState(-1);
  const [hasOtherCourses, setHasOtherCourses] = useState(false);
  const [showManualInput, setShowManualInput] = useState(false);
  const [isPopulating, setIsPopulating] = useState(false);
  const [isPopulated, setIsPopulated] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //Syllabus
  const [syllabusFile, setSyllabusFile] = useState(null);
  const [tempSyllabusFile, setTempSyllabusFile] = useState(null);
  const [syllabusText, setSyllabusText] = useState('');
  const [syllabusFileName, setSyllabusFileName] = useState('');
  const [isReplacing, setIsReplacing] = useState(false);
  const [isPdfUploaded, setIsPdfUploaded] = useState(false);
  //Course Information
  const [modules, setModules] = useState(['']);
  const [courseName, setCourseName] = useState('');
  const [description, setDescription] = useState('');
  const [professors, setProfessors] = useState('');
  const [teachingAssistants, setTeachingAssistants] = useState('');
  const [termName, setTermName] = useState('');
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return {
      day: String(today.getDate()).padStart(2, '0'),
      month: today.toLocaleString('default', { month: 'short' }),
      year: String(today.getFullYear())
    };
  });

  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    const future = new Date(today);
    future.setMonth(today.getMonth() + 4);
    return {
      day: String(future.getDate()).padStart(2, '0'),
      month: future.toLocaleString('default', { month: 'short' }),
      year: String(future.getFullYear())
    };
  });
  // Used for course URL
  const [orgName, setOrgName] = useState('');
  const [courseUrl, setCourseUrl] = useState(''); 
  const [slugifiedOrgName, setSlugifiedOrgName] = useState('');
  const [slugifiedUrl, setSlugifiedUrl] = useState('');
  const [defaultSlugifiedUrl, setDefaultSlugifiedUrl] = useState('');
  const [fullSlugifiedUrl, setFullSlugifiedUrl] = useState('');
  const [editableCourseUrl, setEditableCourseUrl] = useState('');
  const [fixedUrlPart, setFixedUrlPart] = useState('');
  const [currentCourseUrl, setCurrentCourseUrl] = useState('');
  const [urlError, setUrlError] = useState("");
  //Alerts and errors
  const [dateError, setDateError] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [showSlugAlert, setShowSlugAlert] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showSubscriptionSnackbar, setShowSubscriptionSnackbar] = useState(false);
  const [errors, setErrors] = useState({
    courseName: false,
    professors: false,
    dateError: false
  });
  const [syllabusErrors, setSyllabusErrors] = useState('');

  useEffect(() => {
      document.title = "Create a Course - All Day TA";

      const urlParams = new URLSearchParams(window.location.search);
      const success = urlParams.get('success');
      if (success === 'true') {
        setShowSubscriptionSnackbar(true);
      }
  }, []);

  useEffect(() => {
    const fetchCurrAdmin = async () => {
      try {
        const data = await getCurrentAdmin();
        //console.log("Fetched admin:", data.admin_id);
        setAdminInSession(data.admin_id);
      } catch (error) {
        //console.error('Error fetching course professor in session:', error);
      }
    }

    fetchCurrAdmin();
  }, []);

  useEffect(() => {
    const fetchAvailableCourses = async () => {
      try {
        const data = await getAssociatedCoursesListForAdmin();
        const activeCourses = data.filter(course => course.active);
        setHasOtherCourses(activeCourses.length > 0);
      } catch (error) {
        //console.error('Error fetching available courses:', error);
      }
    };

    fetchAvailableCourses();
  }, []);

  useEffect(() => {
    const checkAvailableSubscription = async () => {
      try {
        const response = await axios.get('/api/check-available-subscription');
        console.log('checkAvailableSubscription response:', response);
        if (response.data.error) {
          navigate('/subscriptions');
        }
      } catch (error) {
        navigate('/subscriptions');
      }
    };

    checkAvailableSubscription();
  }, [navigate]);

  const handleFileSelected = async (file) => {
    setIsPopulating(true);
    setShowManualInput(false);
    setTempSyllabusFile(file);
    setSyllabusFileName(file.name);
    setIsPdfUploaded(file.type === 'application/pdf'); //Check if it's a PDF
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post("/api/syllabus/prepopulate", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const syllabusData = response.data;
      if (response.status === 200) {
        if (syllabusData && syllabusData.metadata) {
          if (syllabusData.metadata.class_name) {
              setCourseName(syllabusData.metadata.class_name);
          }
          if (syllabusData.metadata.class_description) {
              setDescription(syllabusData.metadata.class_description);
          }
          if (syllabusData.metadata.professors) {
              setProfessors(syllabusData.metadata.professors.map(prof => prof.professor_name).join(", "));
          }
          if (syllabusData.metadata.teaching_assistants) {
              setTeachingAssistants(syllabusData.metadata.teaching_assistants.map(ta => ta.teaching_assistant_name).join(", "));
          }
          if (syllabusData.metadata.term_name) {
              setTermName(syllabusData.metadata.term_name);
          }
          setSyllabusFile(file);
          setSyllabusText(syllabusData.raw_text);
          setSyllabusFileName(file.name);  // Store the filename
        }
      } else {
        setSyllabusErrors(syllabusData.error);
        setTempSyllabusFile(null);
        setSyllabusFileName(''); 
      }
    } catch (error) {
      //console.error("Error processing syllabus:", error);
      setTempSyllabusFile(null);
      setSyllabusFileName('');
    }

    setIsPopulating(false);
    setIsPopulated(true);
    setIsReplacing(false);
  };

  const handleReplace = () => {
    setIsReplacing(true);
    setTempSyllabusFile(null);
    setSyllabusFileName('');
    setSyllabusFile(null);
    setIsPopulated(false);
    setShowManualInput(false);
  };

  // Required Fields Validation
  const handleCourseNameChange = (e) => {
    const newName = e.target.value;
    setCourseName(newName);
    if (errors.courseName) {
      setErrors(prev => ({ ...prev, courseName: false }));
    }
    updateUrlAlert(newName, courseUrl);
  };

  const handleProfessorNamesChanges = (e) => {
    setProfessors(e.target.value);
    if (errors.professors) {
      setErrors(prev => ({ ...prev, professors: false }));
    }
  };

  // Course URL
  useEffect(() => {
    const fetchOrgName = async () => {
      try {
        const name = await getOrgName();
        setOrgName(name);
        const slugified = slugify(name);
        setSlugifiedOrgName(slugified);
        setFixedUrlPart(`/${slugified}`);
      } catch (error) {
        console.error('Error fetching organization name:', error);
        // Handle the error appropriately in your UI
      }
    };

    fetchOrgName();
  }, []);

  useEffect(() => {
    // Update default slugified URL whenever course name changes
    const slugifiedCourseName = slugify(courseName);
    const newDefaultUrl = `app.alldayta.com/${slugifiedOrgName}/${slugify(courseName)}`;
    setDefaultSlugifiedUrl(newDefaultUrl);
    setFullSlugifiedUrl(newDefaultUrl);
    setCourseUrl(slugifiedCourseName);
    setShowSlugAlert(!!courseName.trim());
  }, [courseName, slugifiedOrgName]);

  const handleCourseUrlChange = (e) => {
    const newEditablePart = e.target.value;
    setCourseUrl(newEditablePart);
    const newFullUrl = `${fixedUrlPart}/${newEditablePart}`;
    setCurrentCourseUrl(newFullUrl);
    setFullSlugifiedUrl(`app.alldayta.com${newFullUrl}`);
    setShowSlugAlert(true);
    setUrlError("");
  };

  const handleCourseUrlBlur = () => {
    if ( slugifiedUrl || courseName ) {
      setShowSlugAlert(true);
    }
  };

  const updateUrlAlert = (name, url) => {
    setShowSlugAlert(!!(name.trim() || url.trim()));
  };

  const UrlDisplay = ({ label, url, onCopy }) => (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography level="body-sm">
        <strong>{label}:</strong> {url}
      </Typography>
      <Button
        variant="plain"
        color="neutral"
        onClick={() => onCopy(url)}
        endDecorator={<ContentCopyIcon />}
        size="sm"
      >
        Copy
      </Button>
    </Stack>
  );

  const copyToClipboard = async (urlToCopy) => {
      try {
          await navigator.clipboard.writeText(urlToCopy);
          setSnackbarOpen(true);
      } catch (err) {
          //console.error('Failed to copy: ', err);
      }
  };


  //Start and End Dates
  useEffect(() => {
    if (startDate) {
      const monthsMap = {
        'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
        'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
      };
      
      const startDateObj = new Date(
        parseInt(startDate.year),
        monthsMap[startDate.month],
        parseInt(startDate.day)
      );
      
      const endDateObj = new Date(startDateObj);
      endDateObj.setMonth(endDateObj.getMonth() + 4);
      
      setEndDate({
        day: String(endDateObj.getDate()).padStart(2, '0'),
        month: endDateObj.toLocaleString('default', { month: 'short' }),
        year: String(endDateObj.getFullYear())
      });
    }
  }, [startDate]);

  const handleStartDateChange = (field) => (_, newValue) => {
    setStartDate(prevDate => {
      if (!prevDate) {
        const today = new Date();
        prevDate = {
          day: String(today.getDate()).padStart(2, "0"),
          month: today.toLocaleString("default", { month: "short" }),
          year: String(today.getFullYear())
        };
      }
      
      const updatedDate = {
        ...prevDate, 
        [field]: newValue || (field === 'day' ? "01" : field === 'month' ? "Jan" : "2024")
      };

      // If month or year changed, validate the day
      if (field === 'month' || field === 'year') {
        updatedDate.day = validateAndAdjustDay(
          updatedDate.day,
          updatedDate.month,
          updatedDate.year
        );
      }
      
      return updatedDate;
    });
  };

  const handleEndDateChange = (field) => (_, newValue) => {
    setEndDate(prevDate => {
      if (!prevDate) {
        const today = new Date();
        prevDate = {
          day: String(today.getDate()).padStart(2, "0"),
          month: today.toLocaleString("default", { month: "short" }),
          year: String(today.getFullYear())
        };
      }
      
      const updatedDate = { 
        ...prevDate, 
        [field]: newValue || (field === 'day' ? "01" : field === 'month' ? "Jan" : "2024")
      };
      
      if (field === 'month' || field === 'year') {
        updatedDate.day = validateAndAdjustDay(
          updatedDate.day,
          updatedDate.month,
          updatedDate.year
        );
      }
      
      return updatedDate;
    });
  };

  // Initial date validation
  useEffect(() => {
    if (startDate && endDate) {
      // Validate the dates when they're first set
      const validatedStartDate = {
        day: validateAndAdjustDay(startDate.day, startDate.month, startDate.year),
        month: startDate.month || "Jan",
        year: startDate.year || "2024"
      };
      
      const validatedEndDate = {
        day: validateAndAdjustDay(endDate.day, endDate.month, endDate.year),
        month: endDate.month || "Jan",
        year: endDate.year || "2024"
      };
      
      setStartDate(validatedStartDate);
      setEndDate(validatedEndDate);
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      validateDates({ startDate, endDate, setDateError, setErrors });
    }
  }, [startDate, endDate]);

  //Modules - not currently implemented
  const handleAddModule = () => {
    setModules([...modules, '']);
  };

  const handleModuleChange = (index, value) => {
    const updatedModules = [...modules];
    updatedModules[index] = value;
    setModules(updatedModules);
  };

  const SyllabusLoading = () => (
    <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems:'center'}}>
      <Grid>
          <img
              src={LoadingIcon}
              alt="Answer loading"
              style={{
                  width: '3rem',
                  height: '3rem',
                  display: 'block',
                  marginLeft: '1.5rem',
              }}
          />
      </Grid>
      <Grid xs sx={{ height: 'auto', flexGrow: '1' }}>
          <Typography level="title-lg">Course information populating from syllabus...</Typography>
      </Grid>
    </Grid>
  );

  const handleSave = async () => {
    //console.log('handleSave function called');
    setIsSubmitted(true);

    const newErrors = {
      courseName: !courseName.trim(),
      professors: !professors.trim(),
      dateError: !!dateError,
      courseUrl: !courseUrl.trim()
    };

    setErrors(newErrors);

    // If there are any errors and no content has been entered, open manual edit
    if ((newErrors.courseName || newErrors.professors || newErrors.dateError) && !isPopulated && !showManualInput) {
      setShowManualInput(true);
      return;
    }

    // If there are any errors, stop the submission
    if (Object.values(newErrors).some(error => error) || adminInSession < 0) {
      return;
    }

    setIsLoading(true);

    const trimmedDescription = description.trim();

    // Construct the full URL
    const fullCourseUrl = `/${slugifiedOrgName}/${courseUrl}`;

    const courseData = {
      courseName,
      professors,
      adminInSession,
      teachingAssistants,
      modules,
      termName,
      trimmedDescription,
      courseUrl: fullCourseUrl,
      startDate,
      endDate,
      syllabusUploaded: !!syllabusFile,
    };
    //console.log('courseData prepared:', courseData);

    if (syllabusFile) {
      courseData.syllabusText = syllabusText;
      courseData.syllabusFileName = syllabusFileName;
    }
  
    const formData = new FormData();
    formData.append('courseData', JSON.stringify(courseData));
    if (syllabusFile && syllabusFile.type === 'application/pdf') {
      formData.append("file", syllabusFile);
    }

    try {
      const response = await axios.post('/api/course_info', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {                    
        // file now uploaded as part of api/course_info
        setStatusMessage('Course data saved successfully');
        //console.log('Server response:', response);
        //console.log('About to navigate...');
        setIsLoading(false);
        navigate('/files', { state: { newCourseCreated: true } });
        if (headerRef.current) {
            headerRef.current.refreshCourseList();
        }
    
        // Clear the form (temporarily)
        setCourseName('');
        setDescription('');
        setCourseUrl('');
        setProfessors('');
        setTeachingAssistants('');
        setTermName('')
        setModules(['']);
        setStartDate({ day: '01', month: 'Jan', year: '2024' });
        setEndDate({ day: '01', month: 'Jan', year: '2024' });
        setSyllabusFile(null);
      }
      
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 400 && error.response.data.error.includes("URL already exists")) {
        setUrlError("This URL already exists. Please choose a different one.");
      } else {
      setStatusMessage('Error saving course data: ' + (error.response?.data?.error || error.message));
      //console.error('Error details:', error);
    }
  }
};

return (
  <Box>
    <header className="min-header-wrapper">
      <Box className="left-wrapper">
          <a href="/" aria-label="Files"><img src={Logo} alt="All Day TA - Files" className="logo" /></a>
          <a href="/" aria-label="Files" className="logo-mobile"><img src={LogoMobile} alt="All Day TA - Files" className="logo-mobile" /></a>
      </Box>
    </header>
  <Container>
    <Grid container spacing={2}>
      <Grid xs={12}>
        {hasOtherCourses && (
          <Button variant="outlined" component="a" href="/files" startDecorator={<ArrowBackwardIcon />} sx={{ marginBottom:2 }}>
            Back to Files
          </Button>
        )}
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ flexWrap: 'wrap' }}
        >
          <Typography level="h1" id="create-course-form">Create a New Course</Typography>
        </Stack>
        
        {(isPopulated) ? (
        <>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          mt={3}
          mb={2}
        >
          <Typography level="title-lg" sx={{ fontSize:'1.25rem'}}>Your Syllabus</Typography>
          <Typography level="body-lg">
            The course information below has been auto-populated with information from your selected syllabus:
          </Typography>
        </Stack>
        </>  
        ) : (
        <>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          mt={3}
          mb={2}
        >
          <Typography level="title-lg" sx={{ fontSize:'1.25rem'}}>Upload Your Syllabus</Typography>
          <Typography level="body-lg">
            All Day TA will pre-populate your course information using your syllabus. Uploading a syllabus helps the AI calibrate how to handle your other files.
          </Typography>
        </Stack>
        </>  
        )}

        <FormControl>
          {isPopulating ? (
            <Box>
              <SyllabusLoading />
              {isPdfUploaded && (
                <FileTypeAlert/>
              )}
            </Box>
          ) : isPopulated && !isReplacing ? (
            <Box>
              <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={2} mb={2} >
                <Typography level="body-lg" color="primary">{syllabusFileName}</Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleReplace}
                >
                  Replace
                </Button>
              </Stack>
              <Divider />
            </Box>   
          ) : showManualInput ? (
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowManualInput(false)}
                sx={{ marginBottom:'1rem' }}
              >
                Upload a Syllabus
              </Button>
              <Divider />
            </Box>
          ) : (
            <>
              <DragAndDropSyllabus 
                setSyllabusFile={handleFileSelected} 
                syllabusUploaded={false} 
                isProcessing={false}
              />
              <Box>
                <Stack direction="row" alignItems="center" gap={2} mb={2}>
                  <Typography level="title-md">Prefer to enter your course information manually?</Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowManualInput(true)}
                  >
                    Switch to Manual Input
                  </Button>
                </Stack>
                <Divider />
              </Box>
            </>
          )}
          <FormHelperText>
            {syllabusErrors}
          </FormHelperText>
        </FormControl>
      </Grid>

      {(isPopulated || showManualInput) && (
      <Grid className="course-information" xs={12} sm={6} pd={3} sx={{ boxSizing: 'border-box', width:'100%'}}>
          <Stack
            direction="column"
            spacing={3}
            role="group"
            aria-labelledby="create-course-form"
          >
          <FormControl error={errors.courseName}>
                <FormLabel>Course Name (Required)</FormLabel>
                <Input variant="outlined" 
                color=
                  {errors.courseName
                    ? "danger"
                    : "primary"
                    } 
                required value={courseName} onChange={(e) => setCourseName(e.target.value)} />
                <FormHelperText>
                  {errors.courseName
                  ? "Course name is required"
                  : "Course name as it will be presented to students"}
                </FormHelperText>
          </FormControl>

          <FormControl>
                <FormLabel>Course Description</FormLabel>
                <Textarea variant="outlined" color="primary" minRows={4} value={description} onChange={(e) => setDescription(e.target.value)} data-testid="course-description"/>
          </FormControl>
          <DatePicker
            labelId="course-start-date"
            label="Course Start Date"
            date={startDate}
            onDateChange={setStartDate}
            showAdditionalInfo={true}
            additionalInfoText="Defaults to today's date"
          />
          <DatePicker
            labelId="course-end-date"
            label="Course End Date"
            date={endDate}
            onDateChange={setEndDate}
            error={!!dateError}
            errorMessage={dateError}
            showAdditionalInfo={true}
            additionalInfoText="Defaults to 4 months after Start Date"
          />
          </Stack>
      </Grid>
    )}
    {(isPopulated || showManualInput) && (
    <Grid xs={12} sm={6}>
        <Stack
            direction="column"
            spacing={3}
            role="group"
            aria-labelledby="create-course-form"
          >
          <FormControl error={errors.professors}>
                <FormLabel>Professor Name(s) (Required)</FormLabel>
                <Input variant="outlined" 
                color=
                  {errors.professors
                    ? "danger"
                    : "primary"
                    } 
                required value={professors} onChange={(e) => setProfessors(e.target.value)} />
                <FormHelperText>
                  {errors.professors
                  ? "At least 1 professor name is required"
                  : "Add multiple names separated with a comma"}
                </FormHelperText>
          </FormControl>

          <FormControl>
                  <FormLabel>Teaching Assistant Name(s)</FormLabel>
                  <Input variant="outlined" color="primary" value={teachingAssistants} onChange={(e) => setTeachingAssistants(e.target.value)} />
                  <FormHelperText>
                    Add multiple names separated with a comma
                  </FormHelperText>
          </FormControl>
          {showSlugAlert && (
              <Alert
                sx={{ mt: 1 }}
                role="alert"
                aria-live="polite"
              >
                <UrlDisplay 
                    label={courseUrl ? "Custom URL" : "Default URL"}
                    url={fullSlugifiedUrl}
                    onCopy={copyToClipboard}
                  />
              </Alert>
            )}
          <FormControl error={!!urlError || errors.courseUrl}>
              <FormLabel>Course URL (Student Access)</FormLabel>
              <Stack
                direction={{ xs: 'column', sm: 'column', md:'column', lg:'row'}} 
                justifyContent="flex-start" 
                alignItems={{ xs: 'flex-start', lg:'center'}}
                mt={1}
                >
                  <Typography>app.alldayta.com/{slugifiedOrgName}/</Typography>
                  <Input
                    variant="outlined"
                    color={(urlError || errors.courseUrl) ? "danger" : "primary"}
                    value={courseUrl}
                    onChange={handleCourseUrlChange}
                    onBlur={handleCourseUrlBlur}
                    sx={{ display:'flex', flexGrow:'1'}}
                  />
              </Stack>
              {urlError && (
                <FormHelperText sx={{ color: 'danger' }}>
                  {urlError}
                </FormHelperText>
              )}
          </FormControl>
          <FormControl>
                <FormLabel>Term Name</FormLabel>
                <Input variant="outlined" color="primary" value={termName} onChange={(e) => setTermName(e.target.value)} />
          </FormControl>
        </Stack>
      {/*Hide Modules until implemented everywhere
      <Box sx={{ mt: 3, mb: 3, textAlign: "left" }}>
        <Stack
          direction="column"
          spacing={1}
          alignItems="flex-start"
        >
            {modules.map((module, index) => (
                <FormControl key={index} sx={{ width:'100%' }}>
                  <FormLabel>{`Module ${index + 1}`}</FormLabel>
                  <Input
                    value={module}
                    onChange={(e) => handleModuleChange(index, e.target.value)}
                    fullWidth
                    variant="outlined"
                    color="primary"
                  />
                </FormControl>
              ))}
          <Button variant="plain" color="primary" startDecorator={<AddIcon />} onClick={handleAddModule}>
            Add Module
          </Button>
        </Stack>
      </Box>
      */}
    </Grid>
    )}
      {statusMessage && (
        <Grid xs={12}>
          <Alert
            variant="soft"
            color={statusMessage.includes('Error') ? 'danger' : 'success'}
            role="alert"
            aria-live="assertive"
            sx={{ mb: 2 }}
          >
            {statusMessage}
          </Alert>
        </Grid>
      )}
      <Grid xs={12} mb={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        {isLoading ? (
          <>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography level="component-md">
                  Please wait while your course is created...
            </Typography>
            <Button 
              variant="solid" 
              color="primary" 
              loading
              loadingPosition="start"
            >
              Saving...
            </Button>
          </Stack>
          </>
        ) : (
          <>
            {!isPopulated && !showManualInput && !courseName && !professors && (
              <Typography level="component-md" sx={{ mb: 1 }}>
                Please upload a syllabus or add course information manually to save.
              </Typography>
            )}
            <Button
              variant="solid"
              color="primary"
              endDecorator={<ArrowForwardIcon />}
              onClick={handleSave}
              disabled={!isPopulated && !showManualInput && !courseName && !professors}
            >
              Save and Continue
            </Button>
          </>
        )}
      </Grid>
    </Grid>
    <Snackbar
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        color="success"
        variant="soft"
        role="alert"
        aria-live="polite"
    >
        URL copied to clipboard!
    </Snackbar>

    <Snackbar
        variant="solid"
        color="success"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSubscriptionSnackbar}
        onClose={() => setShowSubscriptionSnackbar(false)}
        role="alert"
        aria-live="polite"
        startDecorator={<CheckCircleIcon />}
        endDecorator={
          <Button
            variant="solid"
            color="success"
            size="sm"
            onClick={() => setShowSubscriptionSnackbar(false)}
          >
            Dismiss
          </Button>
        }
      >
        Your subscription is active! You can now proceed to create your course.
    </Snackbar>
  </Container>
  </Box>
  );
}