import React from 'react';
import { 
  Box, 
  FormControl, 
  FormLabel, 
  Option, 
  Select, 
  Typography 
} from '@mui/joy';

/**Get the number of days in a given month and year*/
export const getDaysInMonth = (month, year) => {
  const monthMap = {
    'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
    'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
  };
  const monthNum = monthMap[month];
  const yearNum = parseInt(year, 10);
  return new Date(yearNum, monthNum + 1, 0).getDate();
};

/** Parse date string to date object with standardized format*/
export const parseDateString = (dateString) => {
  //console.log("Parsing date string:", dateString);
  
  if (!dateString) {
    const today = new Date();
    return {
      day: String(today.getDate()).padStart(2, "0"),
      month: today.toLocaleString("default", { month: "short" }),
      year: String(today.getFullYear())
    };
  }

  try {
    // Parse the date string that's in format: "Thu, 31 Oct 2024 00:00:00 GMT"
    const parts = dateString.split(' ');
    const day = parts[1];
    const month = parts[2];
    const year = parts[3];

    //console.log("Parsed parts:", { day, month, year });

    // Validate the parsed values
    if (!day || !month || !year || isNaN(parseInt(day)) || isNaN(parseInt(year))) {
      throw new Error("Invalid date parts");
    }

    const result = {
      day: String(parseInt(day)).padStart(2, "0"),
      month: month,  // Already in "short" format (Jan, Feb, etc.)
      year: String(parseInt(year))
    };

    //console.log("Returning formatted date:", result);
    return result;

  } catch (error) {
    //console.error("Error parsing date:", error);
    // Return today's date as fallback
    const today = new Date();
    return {
      day: String(today.getDate()).padStart(2, "0"),
      month: today.toLocaleString("default", { month: "short" }),
      year: String(today.getFullYear())
    };
  }
};

/**Validate and adjust day to be valid for given month/year*/
export const validateAndAdjustDay = (day, month, year) => {
  const monthMap = {
    'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06',
    'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
  };

  const monthNum = monthMap[month];
  if (!monthNum) return day;

  const lastDay = new Date(Number(year), Number(monthNum), 0).getDate();
  return String(Math.min(Number(day), lastDay)).padStart(2, '0');
};

/**Get array of available days for given month/year*/
export const getAvailableDays = (month, year) => {
  if (!month || !year) {
    return Array.from({ length: 31 }, (_, i) => 
      (i + 1).toString().padStart(2, "0")
    );
  }
  
  const daysInMonth = getDaysInMonth(month, year);
  return Array.from({ length: daysInMonth }, (_, i) => 
    (i + 1).toString().padStart(2, "0")
  );
};

/**Convert date object to string format*/
export const dateToString = (dateObj) => {
  if (!dateObj || !dateObj.year || !dateObj.month || !dateObj.day) {
    return "";
  }
  return `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
};

/*Validate Dates*/
export const validateDates = ({ startDate, endDate, setDateError, setErrors }) => {
  if (!startDate || !endDate) return;

  if (!startDate.day || !startDate.month || !startDate.year || 
      !endDate.day || !endDate.month || !endDate.year ||
      !["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].includes(startDate.month) ||
      !["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].includes(endDate.month)) {
    return;
  }

  try {
    const monthsMap = {
      'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
      'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
    };

    const start = new Date(
      parseInt(startDate.year),
      monthsMap[startDate.month],
      parseInt(startDate.day)
    );
    
    const end = new Date(
      parseInt(endDate.year),
      monthsMap[endDate.month],
      parseInt(endDate.day)
    );

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      setErrors(prev => ({ ...prev, dateError: true }));
      return;
    }

    if (end < start) {
      setDateError('End date cannot be before the start date');
      setErrors(prev => ({ ...prev, dateError: true }));
    } else {
      setDateError('');
      setErrors(prev => ({ ...prev, dateError: false }));
    }
  } catch (error) {
    //console.error('Error validating dates:', error);
    setDateError('Error validating dates');
    setErrors(prev => ({ ...prev, dateError: true }));
  }
};

export const DatePicker = ({ 
  labelId, 
  label, 
  date, 
  onDateChange, 
  error, 
  errorMessage,
  showAdditionalInfo = false,
  additionalInfoText = '' 
}) => {
  /*React.useEffect(() => {
    console.log('DatePicker received date:', date);
  }, [date]);*/
  const handleChange = (field) => (_, newValue) => {
    const updatedDate = {
      ...date,
      [field]: newValue || (field === 'day' ? "01" : field === 'month' ? "Jan" : "2024")
    };

    if (field === 'month' || field === 'year') {
      updatedDate.day = validateAndAdjustDay(
        updatedDate.day,
        updatedDate.month,
        updatedDate.year
      );
    }

    onDateChange(updatedDate);
  };

  return (
    <Box sx={{ mb: 3, textAlign: "left" }}>
      <Typography level="component-md" mb={1} id={labelId}>{label}</Typography>
      {showAdditionalInfo && additionalInfoText && (
        <Typography level="component-sm" mb={1}>{additionalInfoText}</Typography>
      )}
      {error && errorMessage && (
        <Typography mb={1} color="danger" level="component-sm" data-testid="date-error-message">{errorMessage}</Typography>
      )}
      <Box 
        sx={{ display: "flex", gap: 2 }}
        role="group"
        aria-labelledby={labelId}
      >
        <FormControl error={error}>
          <FormLabel>Day</FormLabel>
          <Select
            value={date.day}
            onChange={handleChange('day')}
            variant="outlined"
            color="primary"
          >
            {getAvailableDays(date.month, date.year).map((day) => (
              <Option key={day} value={day}>{day}</Option>
            ))}
          </Select>
        </FormControl>

        <FormControl error={error}>
          <FormLabel>Month</FormLabel>
          <Select
            value={date.month}
            onChange={handleChange('month')}
            variant="outlined"
            color="primary"
          >
            {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
              .map((month) => (
                <Option key={month} value={month}>{month}</Option>
              ))}
          </Select>
        </FormControl>

        <FormControl error={error}>
          <FormLabel>Year</FormLabel>
          <Select
            value={date.year}
            onChange={handleChange('year')}
            variant="outlined"
            color="primary"
          >
            {[2024, 2025, 2026, 2027, 2028].map((year) => (
              <Option key={year.toString()} value={year.toString()}>{year}</Option>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default DatePicker;
