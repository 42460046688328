import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    Divider,
    FormControl,
    FormLabel,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    Link,
    List,
    ListItem,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Typography,
  } from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';

export default function InviteStudentModal({ open, onClose, orgId, courseId, onInviteSuccess }) {
  const [studentEmails, setStudentEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (email) => {
    // Basic email validation regex
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleAddEmail = () => {
    const emailsArray = newEmail.split(',').map(email => email.trim());
    const validEmails = emailsArray.filter(email => isValidEmail(email) && !studentEmails.includes(email));
    const invalidEmails = emailsArray.filter(email => !isValidEmail(email));

    if (validEmails.length > 0) {
      setStudentEmails([...studentEmails, ...validEmails]);
      setNewEmail('');
      setEmailError('');
      setAlertMessage('');
    }

    if (invalidEmails.length > 0) {
      setEmailError(`Invalid email${invalidEmails.length > 1 ? 's' : ''}: ${invalidEmails.join(', ')}`);
    } else if (emailsArray.length === 0) {
      setEmailError('Please enter at least one email address');
    }
  };

  const handleRemoveEmail = (email) => {
    setStudentEmails(studentEmails.filter(e => e !== email));
  };

  const handleSendInvites = async () => {
    setAlertMessage('');

    if (studentEmails.length === 0) {
      setAlertMessage('Please add at least one student email before sending invites');
      setAlertType('danger');
      return;
    }

    try {
      setIsLoading(true);
      const responses = await Promise.all(studentEmails.map(email => 
        axios.post('/api/invite_user_collaborator', {
          studentName: email.split('@')[0], // Using part of email as name
          studentOrg: orgId,
          studentEmail: email,
          courseId: courseId
        })
      ));
      
      const successfulInvites = [];
      const failedInvites = [];

      responses.forEach((response, index) => {
        if (response.status === 200) {
          successfulInvites.push(studentEmails[index]);
        } else if (response.status === 409) {
          failedInvites.push(`${studentEmails[index]} (already exists)`);
        } else {
          failedInvites.push(studentEmails[index]);
        }
      });

      setIsLoading(false);

      if (successfulInvites.length > 0) {
        const successMessage = `Successfully invited: ${successfulInvites.join(', ')}`;
        setAlertMessage(successMessage);
        setAlertType('success');
        // Call the onInviteSuccess prop with the success message
        if (onInviteSuccess) {
          onInviteSuccess('Successfully invited students');
        }    
      }

      if (failedInvites.length > 0) {
        setAlertMessage(`Failed to invite: ${failedInvites.join(', ')}`);
        setAlertType('danger');
      }

      // Clear the email list after successful invites
      if (successfulInvites.length > 0) {
        // Close the modal after a short delay to show the success message
        setTimeout(() => {
          onClose();
          setStudentEmails([]);
          setAlertMessage('');
        }, 2000);
      }

    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 409) {
        setAlertMessage(`User already exists: ${error.response.data.email}`);
        setAlertType('danger');
      } else {
        setAlertMessage('An error occurred while sending invitations. Please try again.');
        setAlertType('danger');
        console.error('Error sending invitations:', error);
      }
    }
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          layout='fullscreen'
          variant="outlined"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} justifyContent="flex-end">
              <ModalClose />
            </Grid>
            <Grid xs={12}>
              <Typography level="h1" mb={3}>Invite Students</Typography>
            </Grid>
            <Grid xs={12}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
                mb={4}
                >
                <Typography mb={2}>Enter student emails to invite them to login</Typography>
                <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                <FormControl error={!!emailError}>
                    <FormLabel>Emails</FormLabel>
                        <Input
                          variant="outlined"
                          color={emailError ? "danger" : "primary"}
                          value={newEmail}
                          onChange={(e) => setNewEmail(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleAddEmail();
                            }
                          }}
                        />
                      <FormHelperText>
                        {emailError || "Add multiple emails separated with commas"}
                      </FormHelperText>
                  </FormControl>
                  <Button
                    variant="outlined"
                    endDecorator={<AddIcon />}
                    onClick={handleAddEmail} 
                    >
                      Add
                  </Button>
                </Stack>
                <Divider/>
                <List sx={{  
                    "--ListItem-paddingX": "0px",
                    "--ListItem-paddingY": "8px",
                    }}>
                  {studentEmails.map((email, index) => (
                      <ListItem
                            key={index}
                            sx={{width:'100%', minWidth:'330px'}}
                            endAction={
                              <IconButton 
                                aria-label="Remove" 
                                size="sm" 
                                color="danger"
                                onClick={() => handleRemoveEmail(email)}
                                disabled={isLoading}
                              >
                                <CloseIcon />
                              </IconButton>
                            }
                          >
                            {email}
                      </ListItem>
                  ))}
                </List>
                {alertMessage && (
                  <Alert color={alertType} sx={{ mb: 2 }}>
                    {alertMessage}
                  </Alert>
                )}
              </Stack>
            </Grid>
            <Grid xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={1}
                    >
                      <Button
                        variant="outlined"
                        onClick={onClose}
                        >
                          Cancel
                      </Button>
                    {isLoading ? (
                      <Button 
                        variant="solid" 
                        loading
                        loadingPosition="end"
                      >
                        Sending...
                      </Button>
                    ) : (
                      <Button
                        variant="solid" 
                        onClick={handleSendInvites}
                        endDecorator={<SendIcon />}
                        >
                        Send Invites
                      </Button>
                    )}
                  </Stack>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
