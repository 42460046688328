import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/joy';
import { getAssociatedCoursesListForAdmin } from "../CourseRetrieveAPI";
import { FullScreenEmptyState } from '../components/EmptyStates.js';

const CourseCheck = ({ children }) => {
    const [hasOtherCourses, setHasOtherCourses] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchAvailableCourses = async () => {
            try {
                const data = await getAssociatedCoursesListForAdmin();
                setHasOtherCourses(data.length > 0);
            } catch (error) {
                console.error('Error fetching available courses:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAvailableCourses();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Or a more sophisticated loading indicator
    }

    if (!hasOtherCourses) {
        return (
            <Stack direction="row" alignItems="center" justifyContent="center" gap={2} sx={{height:'50vh', width:'100%'}}>
                <FullScreenEmptyState />
            </Stack>
        );
    }

    return children;
};

export default CourseCheck;