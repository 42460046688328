import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Alert,
  Box,
  Button,
  Skeleton,
  Snackbar,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Container from '../components/Container';
import DragAndDrop from '../components/DragAndDrop';
import DuplicateFileModal from '../components/DuplicateFileModal';
import FileTable from '../components/FileTable';
import TipPopUp from '../components/TipsPopUp';
import { TableLoadingSkeleton } from '../components/LoadingSkeletons';
import { NoFiles} from '../components/EmptyStates.js';
import CourseCheck from '../components/CourseCheck';
import TrialUploadCounter from '../components/TrialUploadCounter';
import { useSubscription } from '../context/SubscriptionContext';

const FileTypeAlert = ({ onClose }) => {
    return (
        <Alert
          variant="soft"
          color="neutral"
          startDecorator={<InfoOutlinedIcon />}
          endDecorator={
            <Button variant="plain" onClick={onClose}>
              Close Tip
            </Button>
          }
          sx={{ mb: 2 }}
          role="alert"
          aria-live="polite"
        >
          <strong>Tip:</strong> PDF and audio files may take longer to process (roughly 10-15 seconds per minute of audio or page of pdf).
        </Alert>
  );
};

export default function Files({ headerRef }) {
    const [showTips, setShowTips] = useState(false);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { subscription } = useSubscription();
    const hasLoadingFiles = useRef(false);
    const [alertFiles, setAlertFiles] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [processingInitiated, setProcessingInitiated] = useState(false);
    const eventSourceRef = useRef(null);
    const [eventSourceConnected, setEventSourceConnected] = useState(false);
    const [isUploadDisabled, setIsUploadDisabled] = useState(false);
    const [uploadCount, setUploadCount] = useState(0);

    const connectToEventSource = () => {
        if (eventSourceConnected) {
            return;
        }
    
        if (process.env.REACT_APP_API_URL) {
            eventSourceRef.current = new EventSource(`${process.env.REACT_APP_API_URL}/stream/file_updates`, { 
                withCredentials: true 
            });
        } else {
            eventSourceRef.current = new EventSource(`${window.location.origin}/stream/file_updates`);
        }
        
        eventSourceRef.current.onopen = function() {
            setEventSourceConnected(true);
        }; 

        eventSourceRef.current.onmessage = function(event) {
            const notifyAdmin = event.data.split(";")[0] == "True";
            const data = JSON.parse(event.data.split(";")[1]);

            let alertMessage = "";
            if (data["type"] == "update") {  
                setFiles(prevFiles =>
                    prevFiles.map(file =>
                        file.file_id == data["file_id"] && file.course_id == data["course_id"] 
                            ? { ...file, ...data["updated_fields"] }
                            : file
                    )
                );
                
                alertMessage = "Course files have been updated by another admin";
            } else if (data["type"] == "add") {
                const newFile = convertToDisplay([data["file"]])[0];
                setFiles((prevFiles) => {
                    newFile.id = prevFiles.length + 1;
                    return [...prevFiles, newFile]
                });
                alertMessage = "New files have been uploaded by another admin";
            } else if (data["type"] == "delete") {
                setFiles((prevFiles) => {
                    let newFiles = prevFiles.filter(file => file.file_id != data["file_id"] && file.course_id == data["course_id"]);
                    newFiles.forEach((file, index) => {
                        file.id = index + 1;
                    });
                    return newFiles;
                });
                alertMessage = "Course files have been deleted by another admin";
            } else if (data["type"].includes("course")) {
                if (headerRef.current) {
                    headerRef.current.refreshCourseList();
                }
                if (data["type"] == "course_update") {
                    alertMessage = "Course information has been updated by another admin";
                } else {
                    alertMessage = "Course has been deleted by another admin"
                }
            }

            if (notifyAdmin) {
                setSnackBarMessage(alertMessage);
                setShowSnackBar(true);
            }
        };
        
        eventSourceRef.current.onerror = function(error) {
            eventSourceRef.current.close();
            axios.post('/api/unsubscribe_file_updates');
            setEventSourceConnected(false);
        };
    }

    useEffect(() => {
        document.title = "Files - All Day TA";

        const urlParams = new URLSearchParams(window.location.search);
        const success = urlParams.get('success');
        if (success === 'true') {
          setShowSnackBar(true);
          setSnackBarMessage("Your subscription has been activated - Thank you for choosing All Day TA!");
        }
        // Unsubscribe from file updates when the page is unloaded (close tab or browser)
        const handleBeforeUnload = async (event) => {
            eventSourceRef.current.close();
            await axios.post('/api/unsubscribe_file_updates');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            eventSourceRef.current.close();
            axios.post('/api/unsubscribe_file_updates');
            setEventSourceConnected(false);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (!eventSourceConnected) {
            connectToEventSource();
        }
    }, [eventSourceConnected])

    const shouldShowTips = (files) => {
        return files.length === 0 || 
               location.state?.newCourseCreated;
    };

    const fetchStatus = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/course_files_retrieve');
            if (response.data) {
                const convertedFiles = convertToDisplay(response.data);
                setFiles(convertedFiles);
                setShowTips(shouldShowTips(convertedFiles));
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchStatus();
    }, [fetchStatus]);

    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.setOnCourseChange(fetchStatus);
        }
    }, [headerRef, fetchStatus]);

    // Convert data fetched into a suitable form before setting it as files
    const convertToDisplay = (data) => {
        //console.log("Currently converting the data to be able to better display it...");
        const convertedData = data.map((item, index) => {
            return {
                course_id: item.course_id,
                file_id: item.file_id,
                org_id: item.org_id,
                id: index + 1,
                title: item.title,
                isactive: item.isactive,
                isoptional: item.isoptional,
                status: item.status,
            };
        });
        //console.log(convertedData);
        return convertedData;
    };

    useEffect(() => {
        hasLoadingFiles.current = files.some(file => file.status === 'Loading');
        //console.log('Has loading files:', hasLoadingFiles.current);
    }, [files]);
    
    useEffect(() => {
        if (location.state?.newCourseCreated) {
            setShowTips(true);
            // Clear the newCourseCreated flag after showing tips
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location, navigate]);


    const handleCloseTipsBox = () => {
        setShowTips(false);
    };

    const handleOpenTipsBox = () => {
        setShowTips(true);
    };

    // PDF & audio file length alert functions
    const checkForAlertFiles = useCallback((newFiles) => {
        const alertTypes = ['.pdf', '.mp3', '.mp4', '.wav', '.ogg', '.m4a'];
        const newAlertFiles = newFiles.filter(file => 
            alertTypes.some(type => file.title.toLowerCase().endsWith(type))
        );

        if (newAlertFiles.length > 0) {
            setShowAlert(true);
        } 
    }, []);

    useEffect(() => {
        checkForAlertFiles(files);
    }, [files, checkForAlertFiles]);

    //Functions for Snackbar that it's okay to leave
    useEffect(() => {
        const allFilesFinished = files.every(file => 
            file.status === 'Processing' || 
            file.status === 'Complete' || 
            file.status === 'Error' ||
            file.status === 'success' ||
            file.status === 'Enhancing'
        );

        const anyFileProcessing = files.some(file => 
            file.status === 'Loading' ||
            file.status === 'Replacing...'
        );

        if (processingInitiated && allFilesFinished && files.length > 0) {
            setSnackBarMessage("All files are now processing in the background, it's safe to navigate away!");
            setShowSnackBar(true);
            setProcessingInitiated(false);
        }

        // Set processingInitiated to true if any file is still processing
        if (anyFileProcessing) {
            setProcessingInitiated(true);
        }
    }, [files]);

    const handleCloseSnackbar = () => {
        setShowSnackBar(false);
    };

    /*Free Trial*/
    const handleTrialStatus = ({ count, isDisabled }) => {
        setIsUploadDisabled(isDisabled);
    };

    return (
        <Container>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ flexWrap: 'wrap' }}
            >   
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                > 
                    <Typography marginRight="1.5rem" level="h1">
                        Files
                    </Typography>
                </Stack>
                {!showTips && (
                <Button
                    variant="plain"
                    color="primary"
                    size="md"
                    endDecorator={<InfoOutlinedIcon />}
                    onClick={handleOpenTipsBox}
                    >
                    Tips for Getting Started
                </Button>
                )}
            </Stack>
            <CourseCheck>
            {showTips && <TipPopUp onClose={handleCloseTipsBox}/>}
            <DragAndDrop 
                setFiles={setFiles} 
                refresh={fetchStatus} 
                disabled={isUploadDisabled}
                disabledMessage={
                    "File uploads are disabled. Please upgrade your subscription to continue uploading files."
                }
                subscriptionTier={subscription?.tier_id}
                remainingUploads={10 - uploadCount}
            />
            {showAlert && (
                <FileTypeAlert 
                    files={files} 
                    onClose={() => setShowAlert(false)} 
                />
            )}
            {isLoading ? (
                <TableLoadingSkeleton />
            ) : files.length > 0 ? (
                <>
                    <TrialUploadCounter onCountChange={({ count, isDisabled }) => {
                        setUploadCount(count);
                        setIsUploadDisabled(isDisabled);
                      }} />
                    <FileTable files={files} refresh={fetchStatus} setFiles={setFiles}/>
                </>
            ) : (
                <>
                    <TrialUploadCounter onCountChange={({ count, isDisabled }) => {
                        setUploadCount(count);
                        setIsUploadDisabled(isDisabled);
                      }} />
                    <NoFiles />
                </>
            )}
            </CourseCheck>
            <Snackbar
                variant="solid"
                color="primary"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showSnackBar}
                onClose={() => setShowSnackBar(false)}
                role="alert"
                aria-live="polite"
                startDecorator={<CheckCircleIcon />}
                endDecorator={
                  <Button
                    variant="solid"
                    size="sm"
                    onClick={handleCloseSnackbar}
                  >
                    Dismiss
                  </Button>
                }
              >
                {snackBarMessage}
            </Snackbar>
        </Container>
    );
}