import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import {
    Alert,
    Button,
    Divider,
    FormControl,
    FormLabel,
    FormHelperText,
    Grid,
    Input,
    Link,
    Stack,
    Typography,
    Box
} from '@mui/joy';
import Container from '../components/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import MediaQuery from "react-responsive";
import HelpIcon from '@mui/icons-material/HelpOutline';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';
import { getAssociatedCoursesListForAdmin } from "../CourseRetrieveAPI";

export default function ProfessorLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { login, isAuthenticated } = useContext(AuthContext);
    const [errors, setErrors] = useState({ email: '', password: '' });

    const validateForm = () => {
        let isValid = true;
        const newErrors = { email: '', password: '' };

        if (!email) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
            isValid = false;
        }

        if (!password) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else if (password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    useEffect(() => {
        document.title = "Login - All Day TA";
        // Check if the user is already authenticated
        if (isAuthenticated) {
            navigate('/files', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    const handleLogin = async (event) => {
        event.preventDefault();
        setMessage(''); // Clear any previous errors
        //console.log("Attempting login to user with email: " + email);

        if (!validateForm()) {
          return;
        }

        // Trim whitespace and convert email to lowercase
        const trimmedEmail = email.trim().toLowerCase();

        try {
            const response = await login(trimmedEmail, password);
            setMessage(response.message);
            //console.log(response);
            if (response.success) {
                //console.log('Login successful');
                
                const associatedCourses = await getAssociatedCoursesListForAdmin();
                if (associatedCourses && associatedCourses.length > 0) {
                    // Redirect to the page they were trying to access, or to /files if no specific destination
                    const destination = location.state?.from?.pathname || '/files';
                    navigate(destination, { replace: true });
                } else {
                    navigate('/create-course', { replace: true });
                }
            } else {
                //console.log('Login failed');
                // The error message is already set by setMessage(response.message)
            }
        } catch (error) {
            //console.error('Login failed:', error);
            setMessage(error.message || 'Login failed');
        }
    };

    const handleonEmailChange = (e) => {
        setEmail(e.target.value);
        if (errors.email) {
            setErrors(prev => ({ ...prev, email: '' }));
        }
    };

    const handleOnPasswordChange = (e) => {
        setPassword(e.target.value);
        if (errors.password) {
            setErrors(prev => ({ ...prev, password: '' }));
        }
    };

    return (
        <Box>
            <header className="min-header-wrapper">
                <Box className="left-wrapper">
                    <a href="/login/admin" aria-label="All Day TA Website"><img src={Logo} alt="All Day TA" className="logo" /></a>
                    <a href="/login/admin" aria-label="All Day TA Website" className="logo-mobile"><img src={LogoMobile} alt="All Day TA" className="logo-mobile" /></a>
                </Box>
                <Box className="right-wrapper">
                    <Button
                        variant="plain"
                        component="a"
                        href="https://alldayta.com/support"
                        startDecorator={<HelpIcon />}
                        >
                        Contact Support
                    </Button>
                </Box>
            </header>
            <Container>
                <Grid container xs={12} mx='auto' spacing={2} justifyContent="space-around"
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '60vh', // Ensure full viewport height
                }}>
                    <Grid container xs={12} sm={4}>
                        <Stack
                            direction="column"
                            alignItems="left"
                            spacing={3}
                            sx={{ width:'100%' }}
                            role="group"
                            aria-labelledby="professor-login"
                            >
                                <Typography level="h1" id="professor-login">Professor Login</Typography>
                                {message && (
                                    <Alert
                                        color='danger'
                                        sx={{ mb: 2 }}
                                        role="alert"
                                        aria-live="polite"
                                        >
                                        {message}
                                    </Alert>
                                )}
                                <FormControl error={!!errors.email}>
                                    <FormLabel>Email:</FormLabel>
                                    <Input
                                    variant="outlined"
                                    color={errors.email ? "danger" : "primary"}
                                    fullWidth 
                                    onChange={handleonEmailChange}
                                    value={email}
                                    />
                                    {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
                                </FormControl>
                                <FormControl error={!!errors.password}>
                                    <FormLabel>Password:</FormLabel>
                                    <Input
                                    variant="outlined"
                                    color={errors.email ? "danger" : "primary"}
                                    value={password}
                                    onChange={handleOnPasswordChange}
                                    type="password"
                                    />
                                    {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
                                </FormControl>
                                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                                    <Button component="a" href="/forgot-password" variant="plain">Forgot Password</Button>
                                    <Button onClick={handleLogin}>Login</Button>
                                </Stack>
                                <Divider />
                                <Typography level="title-md">Don't have an All Day TA account? <Link href="/sign-up" underline="always">Sign up for free</Link></Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}