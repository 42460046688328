// this file is for editing a course
// Note: Safari requires special date handling due to its strict date parsing.
// The date utilities and validation in this component have been specifically
// designed to work across all browsers including Safari's stricter requirements.
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  DialogTitle, 
  DialogContent, 
  DialogActions,
  FormHelperText,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal, 
  ModalDialog, 
  ModalClose, 
  Snackbar,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import Container from "../components/Container";
import LoadingSkeleton from "../components/LoadingSkeletons";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getOrgName } from '../OrgRetrieveAPI';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CourseCheck from '../components/CourseCheck';
import DatePicker, { validateAndAdjustDay, parseDateString, validateDates } from '../components/DatePicker';
import { slugify } from '../components/UrlHandler';

export default function CourseInfo({ headerRef }) {
  //console.log("CourseInfo component mounted");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [org_Id, setOrgId] = useState(null);
  const [course_Id, setCourseId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [modules, setModules] = useState([""]);
  const [courseName, setCourseName] = useState("");
  const [description, setDescription] = useState('');
  const [professors, setProfessors] = useState('');
  const [teachingAssistants, setTeachingAssistants] = useState("");
  const [termName, setTermName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Used for course URL
  const [orgName, setOrgName] = useState('');
  const [slugifiedOrgName, setSlugifiedOrgName] = useState('');
  const [courseUrl, setCourseUrl] = useState('');
  const [originalCourseUrl, setOriginalCourseUrl] = useState('');
  const [slugifiedUrl, setSlugifiedUrl] = useState('');
  const [editableCourseUrl, setEditableCourseUrl] = useState('');
  const [fixedUrlPart, setFixedUrlPart] = useState('');
  const [fullSlugifiedUrl, setFullSlugifiedUrl] = useState('');
  const [urlChanged, setUrlChanged] = useState(false);
  const [currentCourseUrl, setCurrentCourseUrl] = useState('');
  const [urlError, setUrlError] = useState("");
  //Alerts and errors
  const [dateError, setDateError] = useState('');
  const [statusMessage, setStatusMessage] = useState("");
  const [showSlugAlert, setShowSlugAlert] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errors, setErrors] = useState({
    courseName: false,
    professors: false,
    dateError: false
  });

  useEffect(() => {
      document.title = "Edit Course Information - All Day TA";
  }, []);

  const retrieveCourseInfo = useCallback(async () => {
    //console.log("retrieveCourseInfo called");
    setIsLoading(true);
    try {
      const response = await axios.get("/api/course");
      //console.log("Response received from /api/course", response);
      if (response.data && response.data.length > 0) {
        //console.log("Response data:", response.data);
        const courseData = response.data[0];
        const [
          orgId,
          courseId,
          courseName,
          taNames,
          topics,
          term,
          active,
          startDate,
          endDate,
          professor_names,
          description,
          courseUrl,
        ] = courseData;

        //console.log("Course data received:", courseData);

        setOrgId(orgId);
        setCourseId(courseId);
        setCourseName(courseName);
        setProfessors(professor_names.toString().replaceAll(",", ", "));
        setModules(topics);
        setTermName(term);
        setDescription(description);
        setCourseUrl(courseUrl);
        setTeachingAssistants(taNames.toString().replaceAll(",", ", "));
        setStartDate(parseDateString(startDate));
        setEndDate(parseDateString(endDate));
        setCourseUrl(courseUrl);
        setOriginalCourseUrl(courseUrl);
        setCurrentCourseUrl(courseUrl);
        //console.log("Original course URL set:", courseUrl);

      } else {
        //console.log("Response data is empty");
      }
    } catch (error) {
      //console.error("Error fetching course info:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
       //console.log("useEffect called");
    retrieveCourseInfo();
    if (headerRef.current) {
        headerRef.current.setOnCourseChange(retrieveCourseInfo);
    }
  }, [headerRef, retrieveCourseInfo]);


  // Required Fields Validation
  const handleCourseNameChange = (e) => {
    setCourseName(e.target.value);
    if (errors.courseName) {
      setErrors(prev => ({ ...prev, courseName: false }));
    }
  };

  const handleProfessorNamesChanges = (e) => {
    setProfessors(e.target.value);
    if (errors.professors) {
      setErrors(prev => ({ ...prev, professors: false }));
    }
  };
  
  //Course URL
  useEffect(() => {
    const fetchOrgName = async () => {
      try {
        const name = await getOrgName();
        setOrgName(name);
        setSlugifiedOrgName(slugify(name));
      } catch (error) {
        console.error('Error fetching organization name:', error);
        // Handle the error appropriately in your UI
      }
    };

    fetchOrgName();
  }, []);

  useEffect(() => {
    if (courseUrl) {
      const urlParts = courseUrl.split('/');
      const editablePart = urlParts[urlParts.length - 1];
      const fixedPart = urlParts.slice(0, -1).join('/');
      setEditableCourseUrl(editablePart);
      setFixedUrlPart(fixedPart);
    }
  }, [courseUrl]);

  useEffect(() => {
    if (currentCourseUrl) {
      const urlParts = currentCourseUrl.split('/');
      const editablePart = urlParts[urlParts.length - 1];
      const fixedPart = urlParts.slice(0, -1).join('/');
      setEditableCourseUrl(editablePart);
      setFixedUrlPart(fixedPart);
      console.log("URL parts set:", { editablePart, fixedPart });
    }
  }, [currentCourseUrl]);

  const handleCourseUrlChange = (e) => {
    const newEditablePart = e.target.value;
    setEditableCourseUrl(newEditablePart);
    const newFullUrl = `${fixedUrlPart}/${newEditablePart}`;
    setCurrentCourseUrl(newFullUrl);
    setFullSlugifiedUrl(`app.alldayta.com${newFullUrl}`);
    setShowSlugAlert(true);
    const hasChanged = newFullUrl !== originalCourseUrl;
    setUrlChanged(hasChanged);
    console.log("URL changed:", { newFullUrl, originalCourseUrl, hasChanged });
    setUrlError("");
  };

  const handleCourseUrlBlur = () => {
    if (editableCourseUrl !== originalCourseUrl) {
      setShowSlugAlert(true);
    }
  };

  const copyToClipboard = async () => {
      try {
          await navigator.clipboard.writeText(fullSlugifiedUrl);
          setSnackbarOpen(true);
      } catch (err) {
          //console.error('Failed to copy: ', err);
      }
  };

  //Dates - Make sure you exercise caution here, Safari prone to bugs
  const handleStartDateChange = (field) => (_, newValue) => {
    setStartDate(prevDate => {
      if (!prevDate) {
        const today = new Date();
        prevDate = {
          day: String(today.getDate()).padStart(2, "0"),
          month: today.toLocaleString("default", { month: "short" }),
          year: String(today.getFullYear())
        };
      }
      
      const updatedDate = {
        ...prevDate, 
        [field]: newValue || (field === 'day' ? "01" : field === 'month' ? "Jan" : "2024")
      };

      // If month or year changed, validate the day
      if (field === 'month' || field === 'year') {
        updatedDate.day = validateAndAdjustDay(
          updatedDate.day,
          updatedDate.month,
          updatedDate.year
        );
      }
      
      return updatedDate;
    });
  };

  const handleEndDateChange = (field) => (_, newValue) => {
    setEndDate(prevDate => {
      if (!prevDate) {
        const today = new Date();
        prevDate = {
          day: String(today.getDate()).padStart(2, "0"),
          month: today.toLocaleString("default", { month: "short" }),
          year: String(today.getFullYear())
        };
      }
      
      const updatedDate = { 
        ...prevDate, 
        [field]: newValue || (field === 'day' ? "01" : field === 'month' ? "Jan" : "2024")
      };
      
      if (field === 'month' || field === 'year') {
        updatedDate.day = validateAndAdjustDay(
          updatedDate.day,
          updatedDate.month,
          updatedDate.year
        );
      }
      
      return updatedDate;
    });
  };

  // Initial date validation
  useEffect(() => {
    if (startDate && endDate) {
      // Validate the dates when they're first set
      const validatedStartDate = {
        day: validateAndAdjustDay(startDate.day, startDate.month, startDate.year),
        month: startDate.month || "Jan",
        year: startDate.year || "2024"
      };
      
      const validatedEndDate = {
        day: validateAndAdjustDay(endDate.day, endDate.month, endDate.year),
        month: endDate.month || "Jan",
        year: endDate.year || "2024"
      };
      
      setStartDate(validatedStartDate);
      setEndDate(validatedEndDate);
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      validateDates({ startDate, endDate, setDateError, setErrors });
    }
  }, [startDate, endDate]);

  // Also modify the useEffect that updates endDate when startDate changes
  useEffect(() => {
    if (startDate && endDate) {
      //console.log("Updating endDate after startDate change. Current values:", 
      //  { startDate, endDate });
      
      const validatedEndDate = {
        day: validateAndAdjustDay(endDate.day, endDate.month, endDate.year),
        month: endDate.month,
        year: endDate.year
      };
      
      //console.log("Validated endDate:", validatedEndDate);
      setEndDate(validatedEndDate);
      setDateError('');
    }
  }, [startDate]);

  //Modules - Not implemented yet

  const handleAddModule = () => {
    setModules([...modules, ""]);
  };

  const handleModuleChange = (index, value) => {
    const updatedModules = [...modules];
    updatedModules[index] = value;
    setModules(updatedModules);
  };

const handleSave = async () => {
  //console.log("Saving the updated course information...");
  setIsSubmitted(true);

  const newErrors = {
    courseName: !courseName.trim(),
    professors: !professors.trim(),
    dateError: !!dateError
  };

  setErrors(newErrors);

  if (newErrors.courseName || newErrors.professors || newErrors.dateError) {
    return;
  }

  const trimmedDescription = description.trim();

  const fullCourseUrl = `${slugifiedOrgName}/${slugifiedUrl}`;

  const courseData = {
    courseName,
    trimmedDescription,
    professors,
    teachingAssistants,
    modules,
    termName,
    startDate,
    endDate,
    courseUrl: urlChanged ? currentCourseUrl : undefined,
    urlChanged
  };

  //console.log("courseData prepared:", courseData);

  try {
    //console.log("Sending POST request to /api/course_info");
    const response = await axios.patch("/api/course_info", courseData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    //console.log("Response received:", response);

    if (response.status === 200) {
      const courseId = response.data.course.course_id;

      navigate("/files");
      setStatusMessage("Course data updated successfully");
      //console.log("Server response:", response);
      if (headerRef.current) {
        headerRef.current.refreshCourseList();
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data.error.includes("URL already exists")) {
      setUrlError("This URL already exists. Please choose a different one.");
      if (error.response.data.suggested_url) {
          setCurrentCourseUrl(error.response.data.suggested_url);
          const urlParts = error.response.data.suggested_url.split('/');
          setEditableCourseUrl(urlParts[urlParts.length - 1]);
          setFullSlugifiedUrl(`app.alldayta.com${error.response.data.suggested_url}`);
        }
    } else {
      setStatusMessage("Error saving course data: " + (error.response?.data?.message || error.message));
    }
  }
};

const handleDeleteCourse = async () => {
  try {
    //console.log(`Attempting to delete course: /api/delete/course/${org_Id}/${course_Id}`);
    const response = await axios.delete(`/api/delete/course/${org_Id}/${course_Id}`);
    if (response.status === 200) {
      setStatusMessage("Course deleted successfully");
      navigate("/files");
      if (headerRef.current) {
        headerRef.current.refreshCourseList();
      }
    } else {
      setStatusMessage("Error deleting course: " + response.statusText);
    }
  } catch (error) {
    setStatusMessage("Error deleting course: " + error.response.data.message);
    //console.error("Error details:", error.response.data);
  }
  setIsDeleteDialogOpen(false);
};

return (
  <Container>
    {isLoading ? (
      <LoadingSkeleton />
    ) : (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ flexWrap: "wrap" }}
          >
            <Typography level="h1" id="edit-course-form">Edit Course Information</Typography>
          </Stack>
        </Grid>
        <CourseCheck> 
        <Grid
          xs={12}
          sm={6}
          pd={3}
          sx={{ boxSizing: "border-box", width: "100%" }}
        >
          <Stack direction="column" spacing={3}
            role="group"
            aria-labelledby="edit-course-form"
            >
            <FormControl error={errors.courseName}>
              <FormLabel>Course Name (Required)</FormLabel>
              <Input
                variant="outlined"
                color={errors.courseName ? "danger" : "primary"}
                required
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
              />
              <FormHelperText>
                {errors.courseName
                  ? "Course name is required"
                  : "Course name as it will be presented to students"}
              </FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Course Description</FormLabel>
              <Textarea variant="outlined" color="primary" minRows={4} value={description} onChange={(e) => setDescription(e.target.value)} data-testid="course-description" />
            </FormControl>

            <DatePicker
              labelId="course-start-date"
              label="Course Start Date"
              date={startDate}
              onDateChange={setStartDate}
            />
            <DatePicker
              labelId="course-end-date"
              label="Course End Date"
              date={endDate}
              onDateChange={setEndDate}
              error={!!dateError}
              errorMessage={dateError}
            />
          </Stack>
        </Grid>

        <Grid xs={12} sm={6}>
          <Stack direction="column" spacing={3}
            role="group"
            aria-labelledby="edit-course-form"
            >
            <FormControl error={errors.professors}>
              <FormLabel>Professor Name(s) (Required)</FormLabel>
              <Input 
                variant="outlined" 
                color={errors.professors ? "danger" : "primary"} 
                required 
                value={professors} 
                onChange={(e) => setProfessors(e.target.value)} 
              />
              <FormHelperText>
                {errors.professors
                  ? "Professor name(s) are required"
                  : "Add multiple names separated with a comma"}
              </FormHelperText>
            </FormControl>
            
            <FormControl>
              <FormLabel>Teaching Assistant Name(s)</FormLabel>
              <Input
                variant="outlined"
                color="primary"
                value={teachingAssistants}
                onChange={(e) => setTeachingAssistants(e.target.value)}
              />
              <FormHelperText>
                Add multiple names separated with a comma
              </FormHelperText>
            </FormControl>

            <FormControl error={!!urlError}>
              <FormLabel>Course URL (Student Access)</FormLabel>
              <Stack
                direction={{ xs: 'column', sm: 'column', md:'column', lg:'row'}} 
                justifyContent="flex-start" 
                alignItems={{ xs: 'flex-start', lg:'center'}}
                mt={1}
                >
                  <Typography>app.alldayta.com{fixedUrlPart}/</Typography>
                  <Input
                    variant="outlined"
                    color={urlError ? "danger" : "primary"}
                    value={editableCourseUrl}
                    onChange={handleCourseUrlChange}
                    onBlur={handleCourseUrlBlur}
                    sx={{ display:'flex', flexGrow:'1'}}
                  />
              </Stack>
              {urlError && (
                <FormHelperText sx={{ color: 'danger' }}>
                  {urlError}
                </FormHelperText>
              )}
            </FormControl>
            {showSlugAlert && (
              <Alert
                sx={{ mt: 1 }}
                role="alert"
                aria-live="polite"
                endDecorator={
                  <Button
                    variant="plain"
                    color="neutral"
                    onClick={copyToClipboard}
                    endDecorator={<ContentCopyIcon />}
                  >
                    Copy
                  </Button>
                }
              >
                <strong>Your URL:</strong> {fullSlugifiedUrl}
              </Alert>
            )}

            <FormControl>
              <FormLabel>Term Name</FormLabel>
              <Input variant="outlined" color="primary" value={termName} onChange={(e) => setTermName(e.target.value)} />
            </FormControl>
          </Stack>
          {/*Hide Modules until implemented everywhere
          <Box sx={{ mt:3, mb: 3, textAlign: "left" }}>
            <Stack direction="column" spacing={1} alignItems="flex-start">
              {modules.map((module, index) => (
                <FormControl key={index} sx={{ width: "100%" }}>
                  <FormLabel>{`Module ${index + 1}`}</FormLabel>
                  <Input
                    value={module}
                    onChange={(e) =>
                      handleModuleChange(index, e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    color="primary"
                  />
                </FormControl>
              ))}
              <Button
                variant="plain"
                color="primary"
                startDecorator={<AddIcon />}
                onClick={handleAddModule}
              >
                Add Module
              </Button>
            </Stack>
          </Box>*/}
        </Grid>

        <Grid
          xs={12}
          mb={4}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="plain" color="danger" onClick={() => setIsDeleteDialogOpen(true)} endDecorator={<DeleteOutlineIcon />}>
              Delete Course
            </Button>
            <Button variant="outlined" color="primary" onClick={() => navigate("/files")}>
              Cancel
            </Button>
            <Button variant="solid" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Typography>{statusMessage}</Typography>
        </Grid>
        </CourseCheck>
      </Grid>
    )}
    
    <Modal open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
      <ModalDialog>
        <DialogTitle>Confirm Course Deletion</DialogTitle>
        <ModalClose />
        <DialogContent>
          Are you sure you want to delete this course? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleDeleteCourse}>
            Delete
          </Button>
          <Button variant="outlined" color="neutral" onClick={() => setIsDeleteDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
    <Snackbar
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        color="success"
        variant="soft"
        role="alert"
        aria-live="polite"
        className="url-copied"
        data-cy="copy-success-snackbar"
    >
        URL copied to clipboard!
    </Snackbar>
  </Container>
);
}
